var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    directives: [{
      name: "on-clickaway",
      rawName: "v-on-clickaway",
      value: _vm.clickaway,
      expression: "clickaway"
    }],
    attrs: {
      "position": "relative",
      "height": _vm.height
    }
  }, [_c('c-input-group', [_c('c-input', {
    attrs: {
      "type": "text",
      "height": _vm.height,
      "border-radius": "8px",
      "font-size": _vm.fontSize,
      "font-weight": "500",
      "color": _vm.isInvalid ? 'danger.400' : 'primary.400',
      "placeholder": _vm.placeholder,
      "border-color": _vm.withBorder ? _vm.isInvalid ? '#e66673' : '#888' : '',
      "box-shadow": _vm.withBorder ? _vm.isInvalid ? '0 0 0 1px #e66673' : '0 0 0 1px #888' : ''
    },
    on: {
      "keydown": [function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.enter.apply(null, arguments);
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "down", 40, $event.key, ["Down", "ArrowDown"])) { return null; }
        return _vm.down.apply(null, arguments);
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])) { return null; }
        return _vm.up.apply(null, arguments);
      }],
      "input": _vm.change,
      "blur": _vm.blur,
      "focusin": _vm.focusin
    },
    model: {
      value: _vm.selection,
      callback: function callback($$v) {
        _vm.selection = $$v;
      },
      expression: "selection"
    }
  }), _vm.isLoading ? _c('c-input-right-element', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        height: _vm.height
      },
      expression: "{\n        height: height,\n      }"
    }]
  }, [_c('c-spinner', {
    attrs: {
      "thickness": "4px",
      "speed": "0.65s",
      "empty-color": "green.200",
      "color": "primary.400",
      "size": "md",
      "margin-right": "16px"
    }
  })], 1) : _vm._e()], 1), _vm.openSuggestion && !_vm.isLoading ? _c('c-box', {
    attrs: {
      "position": "absolute",
      "width": "100%",
      "box-shadow": "0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -2px rgba(0,0,0,0.1)",
      "border-radius": "8px",
      "margin-top": "2px",
      "max-height": "300px",
      "overflow-y": "auto",
      "background-color": "#FFF",
      "padding": "0",
      "border": "1px solid #C4C4C4",
      "z-index": "10"
    }
  }, [_vm.options.length > 0 ? _c('c-flex', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '>:not(:last-child)': {
          borderBottom: '1px solid #C4C4C4'
        }
      },
      expression: "{\n        '>:not(:last-child)': {\n          borderBottom: '1px solid #C4C4C4',\n        },\n      }"
    }],
    attrs: {
      "flex-direction": "column",
      "width": "100%",
      "z-index": "100",
      "padding-block": "4px"
    }
  }, _vm._l(_vm.options, function (option, i) {
    return _c('c-box', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          'span': {
            fontSize: '18px',
            color: 'primary.400',
            fontWeight: 500
          }
        },
        expression: "{\n          'span': {\n            fontSize: '18px',\n            color: 'primary.400',\n            fontWeight: 500,\n          }\n        }"
      }],
      key: i,
      class: {
        'active': _vm.isActive(i)
      },
      attrs: {
        "padding": "8px 12px",
        "margin-inline": "4px",
        "border-radius": "0px",
        "cursor": "pointer"
      },
      on: {
        "mouseover": function mouseover($event) {
          _vm.current = i + 1;
        },
        "click": function click($event) {
          return _vm.suggestionClick(i);
        }
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": "16px",
        "font-weight": "400",
        "color": "neutral.888888"
      },
      domProps: {
        "innerHTML": _vm._s(_vm.renderHighlightSuggestion(option === null || option === void 0 ? void 0 : option[_vm.valueLabel]))
      }
    })], 1);
  }), 1) : _c('c-box', {
    attrs: {
      "padding": "8px 12px",
      "border-radius": "8px",
      "cursor": "pointer",
      "_hover": {
        background: '#F5F5F5'
      }
    },
    on: {
      "click": function click($event) {
        return _vm.suggestionClick(-1);
      }
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "16px",
      "font-weight": "400",
      "color": "neutral.888888"
    }
  }, [_vm._v(" " + _vm._s(_vm.selection) + " ")])], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }