<template>
  <c-box
    v-on-clickaway="clickaway"
    position="relative"
    :height="height"
  >
    <c-input-group>
      <c-input
        v-model="selection"
        type="text"
        :height="height"
        border-radius="8px"
        :font-size="fontSize"
        font-weight="500"
        :color="isInvalid ? 'danger.400' : 'primary.400'"
        :placeholder="placeholder"
        :border-color="withBorder ? (isInvalid ? '#e66673' : '#888') : ''"
        :box-shadow="withBorder ? (isInvalid ? '0 0 0 1px #e66673' : '0 0 0 1px #888') : ''"
        @keydown.enter="enter"
        @keydown.down="down"
        @keydown.up="up"
        @input="change"
        @blur="blur"
        @focusin="focusin"
      />
      <c-input-right-element
        v-if="isLoading"
        v-chakra="{
          height: height,
        }"
      >
        <c-spinner
          thickness="4px"
          speed="0.65s"
          empty-color="green.200"
          color="primary.400"
          size="md"
          margin-right="16px"
        />
      </c-input-right-element>
    </c-input-group>
    <c-box
      v-if="openSuggestion && !isLoading"
      position="absolute"
      width="100%"
      box-shadow="0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -2px rgba(0,0,0,0.1)"
      border-radius="8px"
      margin-top="2px"
      max-height="300px"
      overflow-y="auto"
      background-color="#FFF"
      padding="0"
      border="1px solid #C4C4C4"
      z-index="10"
    >
      <c-flex
        v-if="options.length > 0"
        flex-direction="column"
        width="100%"
        z-index="100"
        padding-block="4px"
        v-chakra="{
          '>:not(:last-child)': {
            borderBottom: '1px solid #C4C4C4',
          },
        }"
      >
        <c-box
          v-for="(option, i) in options"
          :key="i"
          :class="{'active': isActive(i)}"
          padding="8px 12px"
          margin-inline="4px"
          border-radius="0px"
          cursor="pointer"
          @mouseover="current = i + 1"
          @click="suggestionClick(i)"
          v-chakra="{
            'span': {
              fontSize: '18px',
              color: 'primary.400',
              fontWeight: 500,
            }
          }"
        >
          <c-text
            font-size="16px"
            font-weight="400"
            color="neutral.888888"
            v-html="renderHighlightSuggestion(option?.[valueLabel])"
          />
        </c-box>
      </c-flex>
      <c-box
        v-else
        padding="8px 12px"
        border-radius="8px"
        cursor="pointer"
        :_hover="{
          background: '#F5F5F5',
        }"
        @click="suggestionClick(-1)"
      >
        <c-text
          font-size="16px"
          font-weight="400"
          color="neutral.888888"
        >
          {{ selection }}
        </c-text>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'

export default {
  name: 'BaseInputAutocomplete2',
  mixins: [
    clickaway,
  ],
  props: {
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    valueLabel: {
      type: String,
      default: 'value',
    },
    fontSize: {
      type: [String, Array],
      default: '18px',
    },
    height: {
      type: [String, Array],
      default: '62px',
    },
    withBorder: {
      type: Boolean,
      default: false,
    },
    showEmptyValue: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['blur', 'input', 'trigger-change'],
  data() {
    return {
      open: false,
      current: 0,
    }
  },
  computed: {
    selection: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    openSuggestion() {
      if (this.showEmptyValue) {
        return this.open === true && this.selection
      }
      return this.open === true && this.options.length > 0
    },
  },
  methods: {
    enter() {
      if (this.options.length > 0 && this.current > 0) {
        this.selection = this.options[this.current - 1]?.[this.valueLabel]
      }
      this.open = false
    },
    up() {
      if(this.current > 1)
        this.current--
    },
    down() {
      if(this.current === 0 || this.current < this.options.length)
        this.current++
    },
    isActive(index) {
      return index === this.current - 1
    },
    change() {
      if (this.open == false) {
        this.open = true
        this.current = 0
      }
    },
    blur() {
      this.$emit('blur')
    },
    focusin() {
      this.open = true
      this.current = 0
    },
    suggestionClick(index) {
      if (index !== -1) {
        this.selection = this.options[index]?.[this.valueLabel]
      }
      this.open = false
    },
    clickaway() {
      this.open = false
      this.current = 0
    },
    renderHighlightSuggestion(string) {
      const _selection = this.selection.toLowerCase()
      const _valueLabel = string.toLowerCase()
      if (_valueLabel.includes(_selection)) {
        // Use a case-insensitive regex to replace and preserve original case in the valueLabel output
        const regex = new RegExp(this.selection, 'gi') // 'gi' for global, case-insensitive
        const replace = string.replace(regex, (match) => `<span class="highlight">${match}</span>`)
        return replace
      }
      return string
    },
  },
}
</script>

<style scoped>
.active {
  background-color: #F5F5F5;
}
</style>